import React, { lazy, Suspense } from 'react';
import { Switch, Route, useLocation,Redirect } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import store from 'store-js'

import Loader         from './component/Loader/Loader';
import NavMotion      from './layout/NavMotion';
import MainLayout     from './layout/MainLayout';
import GuestGuard     from './component/Auth/GuestGuard';
import AuthGuard      from './component/Auth/AuthGuard';
import MinimalLayout  from './layout/MinimalLayout';

// @user-management ----------------------------------------------------------------------------
 
    const UserAccess = lazy(() => import('./pages/user-access'))
    const UserManage = lazy(() => import('./pages/user-manage'));

// ---------------------------------------------------------------------------------------------

// @other-routes -------------------------------------------------------------------------------

    const Dashboard         = lazy(() => import('./pages/dashboard'));
    const ArticleSubmission = lazy(() => import('./pages/article-submission'));
    const DraftArticles     = lazy(() => import('./pages/draft-articles'));
    const ImportArticles    = lazy(() => import('./pages/article-excel-import'))

// ---------------------------------------------------------------------------------------------

// @masters ------------------------------------------------------------------------------------

   const WebsiteMaster    = lazy(() => import('./pages/website'))
   const Designation      = lazy(() => import('./pages/designation'))
   const BroadCategory    = lazy(() => import('./pages/broad-category'));
   const SpecificCategory = lazy(() => import('./pages/specific-category'));
   const Project          = lazy(() => import('./pages/project'))
   
// ---------------------------------------------------------------------------------------------

 
// materially theme ---------------------------------------------------------------------------------

    const AuthLogin        = lazy(() => import('./pages/login'));
    const ForgotPassword   = lazy(() => import('./pages/forgot-password/index.js'));

// system routes ------------------------------------------------------------------------------------


const Routes = () => {

    const location   = useLocation();
    const permission = {};

    const RoutesPath = [ 
        '/dashboard',
        '/designation',
        '/broad-category',
        '/specific-category',
        '/website-master',
        '/user-manage',
        '/user-access',
        '/article-submission',
        '/draft-articles',
        '/import-articles',
        '/project',
        '/sample'
    ]

    const Template = (props) => {

        switch(props.component){
            case 'designation'        : return <Designation       permissions={props.permissions}></Designation>;            
            case 'specific-category'  : return <SpecificCategory  permissions={props.permissions}></SpecificCategory>;  
            case 'broad-category'     : return <BroadCategory     permissions={props.permissions}></BroadCategory>;        
            case 'website-master'     : return <WebsiteMaster     permissions={props.permissions}></WebsiteMaster>;              
            case 'user-manage'        : return <UserManage        permissions={props.permissions}></UserManage>;              
            case 'user-access'        : return <UserAccess        permissions={props.permissions}></UserAccess>;
            case 'article-submission' : return <ArticleSubmission permissions={props.permissions}></ArticleSubmission>;        
            case 'draft-articles'     : return <DraftArticles     permissions={props.permissions}></DraftArticles>;      
            case 'import-articles'    : return <ImportArticles    permissions={props.permissions}></ImportArticles>;                    
            case 'project'            : return <Project permissions={props.permissions} ></Project>;                    

            default : return <Redirect to='/dashboard'></Redirect>;
        }
    }

    const SecureRoute = (props) => {

        const authentication = store.get('authentication') === undefined ? '' : store.get('authentication').permissions ?? ''
        const response = { show: true, permissions : {}, path : props.location.pathname  }

        if(authentication !== undefined) {
            if(authentication !== '') { response.permissions = JSON.parse(authentication) }
        }

        if(Object.keys(response.permissions).length === 0) return <Template permissions={{}} component={undefined}></Template>

        const components = {
            '/designation'        : { permission : 'designation_view'       , component : 'designation'        },
            '/broad-category'     : { permission : 'broadCategory_view'     , component : 'broad-category'     },
            '/specific-category'  : { permission : 'specificCategory_view'  , component : 'specific-category'  },
            '/website-master'     : { permission : 'website_view'           , component : 'website-master'     },
            '/user-manage'        : { permission : 'userManagement_view'    , component : 'user-manage'        },
            '/user-access'        : { permission : 'userAccess_view'        , component : 'user-access'        },
            '/article-submission' : { permission : 'articleSubmission_view' , component : 'article-submission' },
            '/draft-articles'     : { permission : 'draftArticles_view'     , component : 'draft-articles' },
            '/import-articles'    : { permission : 'importArticles_view'    , component : 'import-articles' },
            '/project'            : { permission : 'project_view'           , component : 'project' },
        }

        const getComponent  = components[response.path]
        const getPermission = response.permissions[getComponent.permission]

        if(getPermission === undefined) return <Template permissions={{}} component={undefined}></Template>

        if(getPermission.value === false) return <Template permissions={{}} component={undefined}></Template>
        
        return <Template permissions={response.permissions} component={getComponent.component}></Template>
    }

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route path={['/login','/forgot-password']}>
                        <MinimalLayout permission={permission}>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <GuestGuard>
                                        <Route path="/login" component={AuthLogin} />
                                        <Route path="/forgot-password" component={ForgotPassword}></Route>
                                    </GuestGuard>
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>

                    <Route path={RoutesPath}>
                        <MainLayout permission={permission}>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard routes={RoutesPath}>
                                        <Route path="/dashboard"             component={Dashboard}     />                                        
                                        
                                        <Route path="/user-manage"           component={SecureRoute}   />
                                        <Route path="/user-access"           component={SecureRoute}   />

                                        <Route path="/article-submission"    component={SecureRoute}   />
                                        <Route path="/draft-articles"        component={SecureRoute}   />
                                        <Route path="/import-articles"       component={SecureRoute}   />
                                        
                                        <Route path="/website-master"        component={SecureRoute}   />
                                        <Route path="/broad-category"        component={SecureRoute}   />
                                        <Route path="/specific-category"     component={SecureRoute}   />
                                        <Route path="/designation"           component={SecureRoute}   />
                                        <Route path="/project"               component={SecureRoute}></Route>
                                        <Route path="/sample"                component={SecureRoute}></Route>
                                        
                                     </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                    <Route path="*"><Redirect to='/dashboard'></Redirect></Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
