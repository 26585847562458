class initialState {
    constructor(){
        this.formSubmit = false;
        this.userEmail  = '';
        this.textFieldMessage = '';
        this.textFieldError   = false;
        this.errorMessage     = '';
        this.disableButton    = false;
        this.showLoader       = false;
        this.validation       = {
            rules    : { userEmail : 'required|email' },
            messages : { 
                required : 'Please enter your email address',
                email    : 'Please enter a valid email address'
            }
        }
    }
}

const ForgotPasswordScreen = (state = new initialState(), action) => {

    switch (action.type) {

        case 'sendEmail': { return { ...state, ...action.payload } }

        default: return state;
    }
}

export default ForgotPasswordScreen;