
const initialState = {
    activeScreen   : 'login-screen',
    userInput      : '-', 
    userEmail      : '-',
    serverResponse : '-',
    permissions    : '',
    devenv         : 'production'
}

const AuthenticationReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'user-login': { return { ...state, ...action.payload } }

        default: return state;
    }
}

export default AuthenticationReducer;