import React from 'react';
import { Redirect } from 'react-router-dom';
import store from 'store-js'
import { makeStyles } from "@material-ui/core/styles";
import { StyledEngineProvider } from '@mui/material/styles';
import { Grid } from '@material-ui/core';



const GuestGuard = ({ children }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.common.black,
            height: '100vh',
            minHeight: '100vh',
            display : 'flex',
            alignItems : 'center',
            padding    : '0px',
            margin     :'0px',
            justifyContent : 'space-evenly'
        },
    }));

    const templateStyle = useStyles();

    const authentication = store.get('authentication')

    if ( authentication !== undefined || authentication?.login === true )  return <Redirect to="/dashboard/default" />;

    return(
        <StyledEngineProvider injectFirst>
            <Grid container justifycontent="center" alignItems="center" className={templateStyle.root}>
                { children }
            </Grid>
        </StyledEngineProvider>
    )
};

export default GuestGuard;
