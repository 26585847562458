class initialState {
    constructor(){
        this.formSubmit       = false;
        this.newPassword      = '';
        this.confirmPassword  = '';
        
        this.textFieldErrorOne  = false;
        this.textFieldErrorTwo  = false;

        this.textFieldMessageOne  = '';
        this.textFieldMessageTwo  = '';

        this.errorMessage     = '';
        this.disableButton    = false;
        this.showLoader       = false;
        this.validation       = {
            rules    : { 
                newPassword     : 'required|min:8',
                confirmPassword : 'required|min:8',
            },
            messages : { 
                required : 'Please enter the password',
                min      : 'Please enter minimum 8 digits'
            }
        }
    }
}

const ChangePasswordScreen = (state = new initialState(), action) => {

    switch (action.type) {

        case 'changePassword': { return { ...state, ...action.payload } }

        default: return state;
    }
}

export default ChangePasswordScreen;