class initialState {
    constructor(){
        this.formSubmit    = false;
        this.otp           = '';
        this.errorMessage  = '';
        this.disableButton = false;
        this.showLoader    = false;
    }
}

const OtpVerifyScreen = (state = new initialState(), action) => {

    switch (action.type) {

        case 'verifyOTP': { return { ...state, ...action.payload } }

        default: return state;
    }
}

export default OtpVerifyScreen;