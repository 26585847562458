import React from 'react';
import store from 'store-js'


import { makeStyles, List, Typography } from '@material-ui/core';

import NavItem from './../NavItem';
import NavCollapse from './../NavCollapse';

const useStyles = makeStyles((theme) => ({
    menuCaption: {
        ...theme.typography.menuCaption,
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption,
    },
}));

const NavGroup = (props) => {
    const { item } = props;
    const classes = useStyles();
    const authentication = store.get('authentication') === undefined ? '' : store.get('authentication').permissions ?? ''

    const checkPermission = (route) => {

        if(route === 'dashboard') return true;

        const response = { show: true, permissions : {} }

        if(authentication !== undefined) {
            if(authentication !== '') { response.permissions = JSON.parse(authentication) }
        }

        if(Object.keys(response.permissions).length === 0) return false;

        if(response.permissions[route] === undefined) return false

        if(response.permissions[route].value === false) return false

        return true;
    }

    const toggleGroup = (group) => {

        const response = { show: true, permissions : {} }

        if(authentication !== undefined) {
            if(authentication !== '') { response.permissions = JSON.parse(authentication) }
        }

        if(Object.keys(response.permissions).length === 0) return false;

        group.childrenPermission.map((child) => {

            if(response.permissions[child] === undefined) response.show = false;

            else response.show = response.permissions[child].value

            return null
        })

        return response.show
    }

    const items = item.children.map((menu) => {

        switch (menu.type) {

            case 'collapse': { return toggleGroup(menu) ? <NavCollapse key={menu.id} menu={menu} level={1} /> : <React.Fragment key={menu.id}></React.Fragment>  }

            case 'item': { return checkPermission(menu.permission) ? <NavItem key={menu.id} item={menu} level={1} /> : <React.Fragment key={menu.id}></React.Fragment>; }
                
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <List
            subheader={
                <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom>
                    {item.title}
                    {item.caption && (
                        <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )}
                </Typography>
            }
        >
            {items}
        </List>
    );
};

export default NavGroup;
