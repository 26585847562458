
const initialStates = {
    id                  : { value: '', error: false,  errorMessage : 'hi' },
    userRole            : { value: '', error: false,  errorMessage : 'hi' },
    userName            : { value: '', error: false,  errorMessage : 'hi' },
    userEmail           : { value: '', error: false,  errorMessage : 'hi' },
    userContact         : { value: '', error: false,  errorMessage : 'hi' },
    userPassword        : { value: '', error: false,  errorMessage : 'hi' },
    userDesignation     : { value: '', error: false,  errorMessage : 'hi' },
    userReportingPerson : { value: '', error: false,  errorMessage : 'hi' },
    userAccounting      : { value: '', error: false,  errorMessage : 'hi' },
}

const UserManageMentReducer = (state = initialStates, action) => {

    switch (action.type) {

        case 'userManage': { return { ...state, ...action.payload } }

        default: return state;
    }
}

export default UserManageMentReducer;