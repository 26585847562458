import React from 'react';
import { Redirect } from 'react-router-dom';
import store from 'store-js'


const AuthGuard = ({ children }) => {

    const authentication = store.get('authentication')

    if ( authentication === undefined || authentication?.login === false )  return <Redirect to="/login" />;

    return children;
};

export default AuthGuard;
