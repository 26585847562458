import React from 'react';
import { Box, Grid, makeStyles, IconButton, Hidden } from '@material-ui/core';

import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import ProfileSection from './ProfileSection';

import logo from './pubtribe.png';

import { drawerWidth } from './../../../store/constant';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        backgroundColor: 'orange'
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
        marginLeft:'2em'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
    logoStyle:{
        width:'100%',
    }
}));

const Header = (props) => {
    const { drawerToggle } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box width={drawerWidth}>
                <Grid container justifycontent="space-between" alignItems="center">
                    <Hidden smDown>
                        <Grid item>
                            
                        </Grid>
                    </Hidden>
                    <Grid item style={{display:'flex'}}>
                    <Box mt={0.5}>
                                <img src={logo} className={classes.logoStyle} alt="..." />
                            </Box>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            style={{ color:'#242c3a' }}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={drawerToggle}
                        >
                            <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                        
                    </Grid>
                </Grid>
            </Box>
            <div className={classes.grow} />

            <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
