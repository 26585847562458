
const initialState = {
    activeScreen   : 'forgot-password',
    userInput      : '-', 
    userEmail      : '-',
    serverResponse : '-',
    devenv         : 'production'
}

const ForgotPasswordReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'changeScreen': { return { ...state, ...action.payload } }

        default: return state;
    }
}

export default ForgotPasswordReducer;