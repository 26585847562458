import { combineReducers }   from 'redux';
import customizationReducer  from './customizationReducer';
import snackbarReducer       from './snackbarReducer';
import ForgotPasswordReducer from './ForgotPasswordReducer'
import UserManageMentReducer from './UserManageMentReducer'
import AuthenticationReducer from './Authentication'

// @import-forgot-password-reducers -------------------------------------

   import ForgotPasswordScreen from '../pages/forgot-password/Reducers/ForgotPasswordScreen'
   import OtpVerifyScreen      from '../pages/forgot-password/Reducers/OtpVerifyScreen'
   import ChangePasswordScreen from '../pages/forgot-password/Reducers/ChangePasswordScreen'


const reducer = combineReducers({
    customization         : customizationReducer,
    snackbar              : snackbarReducer,
    userManageMentReducer : UserManageMentReducer,
    forgotPassword        : ForgotPasswordReducer,
    forgotPasswordScreen  : ForgotPasswordScreen,
    otpVerifyScreen       : OtpVerifyScreen,
    changePasswordScreen  : ChangePasswordScreen,
    authentication        : AuthenticationReducer
});

export default reducer;
