import HomeOutlinedIcon       from '@material-ui/icons/HomeOutlined';
import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import AppsOutlinedIcon       from '@material-ui/icons/AppsOutlined';

const icons = {
    NavigationOutlinedIcon: NavigationOutlinedIcon,
    HomeOutlinedIcon: HomeOutlinedIcon,
    AppsOutlinedIcon: AppsOutlinedIcon,
};

export default {
    items: [
        {
            id: 'navigation',
            title: '',
            caption: '',
            type: 'group',
            icon: icons['NavigationOutlinedIcon'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: icons['HomeOutlinedIcon'],
                    url: '/dashboard/default',
                    permission: 'dashboard',
                },
                {
                    id: 'master',
                    title: 'Masters',
                    type: 'collapse',
                    icon: icons['NavigationOutlinedIcon'],
                    childrenPermission:['website_view','project_view','broadCategory_view','specificCategory_view'],
                    children: [
                        {
                            id: 'website',
                            title: 'Website',
                            type: 'item',
                            url: '/website-master',
                            permission : 'website_view',
                        },
                        {
                            id: 'project',
                            title: 'Project',
                            type: 'item',
                            url: '/project',
                            permission : 'project_view',
                        },
                        {
                            id: 'broad-category"',
                            title: 'Broad Category',
                            type: 'item',
                            url: '/broad-category',
                            permission : 'broadCategory_view',
                        },
                        {
                            id: 'specific-category',
                            title: 'Specific Category',
                            type: 'item',
                            url: '/specific-category',
                            permission : 'specificCategory_view',
                        },
                    ],
                },
                {
                    id: 'user-management',
                    title: 'User Management',
                    type: 'collapse',
                    icon: icons['AppsOutlinedIcon'],
                    childrenPermission:['userManagement_view','userAccess_view','designation_view'],
                    children: [
                        {
                            id    : 'user-manage',
                            title : 'User Manage',
                            type  : 'item',
                            url   : '/user-manage',
                            permission : 'userManagement_view',
                        },
                        {
                            id: 'user-access',
                            title: 'User Access',
                            type: 'item',
                            url: '/user-access',
                            permission : 'userAccess_view',
                        },
                        {
                            id    : 'designation',
                            title : 'Designation',
                            type  : 'item',
                            url   : '/designation',
                            permission : 'designation_view',
                        },
                    ]
                },
                {
                    id: 'article-submission',
                    title: 'Article Submission',
                    type: 'item',
                    permission : 'articleSubmission_view',
                    icon: icons['AppsOutlinedIcon'],
                    url: '/article-submission',
                    target: false
                },
                {
                    id: 'draft-articles',
                    title: 'Draft Articles',
                    type: 'item',
                    permission : 'draftArticles_view',
                    icon: icons['AppsOutlinedIcon'],
                    url: '/draft-articles',
                    target: false
                },
                {
                    id: 'import-articles',
                    title: 'Import Articles',
                    type: 'item',
                    permission : 'importArticles_view',
                    icon: icons['AppsOutlinedIcon'],
                    url: '/import-articles',
                    target: false
                },
            ],
        },
    ],
};


 